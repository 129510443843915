import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { round, mean } from 'lodash';
import { setWidgetLoaded } from 'js/reducers/report.js';
import {
    showTable,
    showChart,
    renderTitle,
    renderSubtitle,
    renderDescription,
    hiddenChart,
} from 'js/vendor/reports/helpers.js';

import query from './query.graphql';
import SummaryChart from './charts/SummaryChart';
import RespondentTotalChart from './charts/RespondentTotalChart';
import RespondentTotalTable from './tables/RespondentTotalTable';
import BenchmarkTable from '../../tables/BenchmarkTable';
import ItemRespondentChart from './charts/ItemRespondentChart';
import ItemRespondentTable from './tables/ItemRespondentTable';
import ItemQuestionMeans from '../../tables/ItemQuestionMeans';
import ItemQuestionTable from './tables/ItemQuestionTable';

const SecCSatisfactionSection = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const items = ['C1', 'C2', 'C3', 'C4'];
    const respondents = [
        {
            id: '5',
            label: 'Youth',
        },
        {
            id: '3',
            label: 'Caregiver',
        },
    ].filter(r => props.filters.respondentSelectorField.includes(r.id));

    const levels = [
        { name: 'highFidelity', label: 'High Fidelity' },
        { name: 'adequate', label: 'Adequate' },
        { name: 'borderline', label: 'Borderline' },
        { name: 'inadequate', label: 'Inadequate' },
    ];

    const scales = [
        {
            name: 'satisfaction',
            label: 'Satisfaction',
        },
    ];

    const maxCC = 10;
    const hideChart =
        props.filters.careCoordSelectorField && Object.keys(props.filters.careCoordSelectorField).length > maxCC;

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getSectionData = async () => {
        const variables = {
            deploymentIds: props.filters.evalCycleSelectorField || [],
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    const parseSummaryChartData = () => {
        const ccList = props.filters.careCoordSelectorField.map(cc => {
            const pawnDashIndex = cc.lastIndexOf('-');
            return cc.substring(0, pawnDashIndex);
        });
        const totalCaregiver = [];
        const totalYouth = [];
        let subtotalCaregiver = [];
        let subtotalYouth = [];

        for (const res of respondents) {
            if (data.flows[res.label].frequency) {
                for (const cc of data.flows[res.label].frequency) {
                    if (ccList.includes(cc.value)) {
                        if (cc.mean) {
                            if (res.label === 'Caregiver') {
                                subtotalCaregiver.push(cc.mean);
                            } else {
                                subtotalYouth.push(cc.mean);
                            }
                        }
                    }
                }
            }
        }

        if (subtotalCaregiver.length > 0) {
            totalCaregiver.push(round(mean(subtotalCaregiver), 2));
        }
        if (subtotalYouth.length > 0) {
            totalYouth.push(round(mean(subtotalYouth), 2));
        }

        let d = [];
        if (props.filters.respondentSelectorField.includes('3')) {
            d.push({
                respondent: 'Caregiver',
                mean: round(mean(totalCaregiver), 2) || 0,
            });
        }
        if (props.filters.respondentSelectorField.includes('5')) {
            d.push({ respondent: 'Youth', mean: round(mean(totalYouth), 2) || 0 });
        }
        return d;
    };

    const getMarkersFor = id => {
        const benchmark = 'upper';
        const key = respondents.filter(r => r.id === id)[0].label;

        let markers = [];
        for (var i of [
            // ['Inadequate', 'red'],  remove this benchmark per client and add text
            ['Adequate', 'blue'],
            ['Borderline', 'orange'],
            ['HighFidelity', 'green'],
        ]) {
            const value = window.cccisd.appDefs.wfiBenchmarks[`${key}_${i[0]}_${benchmark}`].satisfaction;

            const lower = window.cccisd.appDefs.wfiBenchmarks[`${key}_${i[0]}_lower`].satisfaction;

            const mark = {
                axis: 'y',
                value: lower, // per client, show line at bottom of range
                legend: `${i[0].replace(/([A-Z])/g, ' $1')} (${lower}-${value})`,
                lineStyle: {
                    stroke: i[1],
                },
                textStyle: {
                    fill: i[1],
                },
                legendOffsetY: 5,
                legendOffsetX: -150,
            };
            markers.push(mark);
        }
        return markers;
    };

    return (
        <>
            <div>
                {renderTitle('Total Satisfaction Score')}
                {renderDescription(`Any score below the Borderline range is considered in the Inadequate range.`)}
                {showChart(
                    <SummaryChart
                        data={!loading && parseSummaryChartData()}
                        markers={getMarkersFor(respondents[0].id)}
                    />,
                    loading
                )}
            </div>

            {respondents.map(r => (
                <div key={r.id}>
                    {renderSubtitle(
                        `Section C: Total Satisfaction Score by Care Coordinator for WFI-EZ ${r.label} Form`
                    )}

                    {hideChart
                        ? hiddenChart(`Chart hidden for reports with more than ${maxCC} Care Coordinators`)
                        : showChart(
                              <RespondentTotalChart
                                  data={data}
                                  respondent={r}
                                  careCoordSelectorField={props.filters.careCoordSelectorField}
                                  markers={getMarkersFor(r.id)}
                              />,
                              loading
                          )}

                    {showTable(
                        <RespondentTotalTable
                            data={data}
                            respondent={r}
                            careCoordSelectorField={props.filters.careCoordSelectorField}
                        />,
                        loading
                    )}

                    {showTable(<BenchmarkTable type={r.label} upper scales={scales} levels={levels} />, loading)}
                </div>
            ))}

            {renderSubtitle('Item Responses')}
            {renderDescription(
                `Section C items are rated on a 5-point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). Users also have the option to respond "don't know" to any item, which renders the item "blank".`
            )}

            {items.map(item => {
                return (
                    <div key={item}>
                        {renderSubtitle(`Item ${item}`)}
                        {hideChart
                            ? hiddenChart(`Chart hidden for reports with more than ${maxCC} Care Coordinators`)
                            : showChart(
                                  <ItemRespondentChart
                                      data={data}
                                      item={item}
                                      careCoordSelectorField={props.filters.careCoordSelectorField}
                                      respondents={respondents}
                                  />,
                                  loading
                              )}
                    </div>
                );
            })}

            {respondents.map(r => (
                <div key={r.id} style={{ marginTop: '80px' }}>
                    {renderSubtitle(`Section C Item Responses by Care Coordinator for WFI-EZ ${r.label} Form`)}

                    {showTable(<ItemRespondentTable items={items} data={data} respondent={r} />, loading)}

                    {showTable(<ItemQuestionMeans items={items} level={r.label.replace(' ', '')} />, loading)}

                    {renderDescription('The table below presents the survey question for each item.')}
                    <ItemQuestionTable items={items} respondent={r} />
                </div>
            ))}
        </>
    );
};

SecCSatisfactionSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(SecCSatisfactionSection);
