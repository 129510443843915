import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const RespondentItemChart = props => {
    const { data, respondent, item, groupLevelMap } = props;

    const altItems = ['D5', 'D6', 'D7', 'D8', 'D9'];
    const doAlt = altItems.includes(item);

    const getData = () => {
        const { siteList } = data.groups;
        let barGroup = { item };
        for (var g of Object.keys(groupLevelMap)) {
            const sites = groupLevelMap[g];
            let groupTotal = 0;
            let groupN = 0;
            for (var siteId of sites) {
                const site = siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    const siteN = site.descendantRoles[respondent.name][item + 'N'];
                    if (siteN) {
                        groupN += siteN;
                    }
                    groupTotal += site.descendantRoles[respondent.name][item] || 0;
                }
            }
            const percent = round((groupTotal / groupN) * 100 || 0, 1);
            const score = round(groupTotal / groupN || 0, 2);
            if (groupN > 0) {
                barGroup[g] = doAlt ? score : percent;
            }
        }
        return [barGroup];
    };

    const chartData = getData(item);

    const longestLabel = Math.max(
        ...Object.keys(chartData[0]).map(o => {
            return o.length;
        })
    );
    const right = 9 * longestLabel + 20;

    const yAxisMax = doAlt ? 3 : 100;

    return (
        <>
            <div
                style={{
                    width: '770px',
                    height: `400px`,
                }}
            >
                <ResponsiveBar
                    indexBy="item"
                    groupMode="grouped"
                    maxValue={yAxisMax}
                    labelFormat={d => <tspan y={-8}>{d}</tspan>}
                    label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                    labelSkipWidth={0}
                    labelSkipHeight={0}
                    keys={Object.keys(chartData[0]).filter(k => k !== 'item')}
                    theme={{ fontSize: 16 }}
                    data={getData()}
                    margin={{ top: 15, right, bottom: 50, left: 50 }}
                />
            </div>
        </>
    );
};

RespondentItemChart.propTypes = {
    data: PropTypes.array,
    respondent: PropTypes.object,
    item: PropTypes.array,
    groupLevelMap: PropTypes.object,
    renderSubtitle: PropTypes.func,
};

export default RespondentItemChart;
