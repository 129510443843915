import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import query from './query.graphql';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import { mean, round, startCase } from 'lodash';

import QuestionTable from '../../tables/QuestionTable';
import RespondentTotalChart from './charts/RespondentTotalChart';
import RespondentTotalTable from './tables/RespondentTotalTable';
import ItemRespondentTable from './tables/ItemRespondentTable';
import ItemRespondentChart from './charts/ItemRespondentChart';
import SummaryChart from './charts/SummaryChart';
import ItemQuestionMeans from '../../tables/ItemQuestionMeans';
import BenchmarkTable from '../FidelitySectionB/tables/BenchmarkTable';
import { setWidgetLoaded } from 'js/reducers/report.js';
import {
    showChart,
    showTable,
    renderTitle,
    renderSubtitle,
    getGroupLevelMap,
    renderDescription,
    hiddenChart,
} from 'js/vendor/reports/helpers.js';
import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';

const respondents = {
    2: { name: 'careCoordinator', label: 'Care Coordinator' },
    3: { name: 'caregiver', label: 'Caregiver' },
    4: { name: 'teamMember', label: 'Team Member' },
    5: { name: 'youth', label: 'Youth' },
};

const items = ['C1', 'C2', 'C3', 'C4'];

const SatisfactionSection = props => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    // should show charts with more than X groups
    const maxGroups = 10;
    const hideChart = groupLevelMap && Object.keys(groupLevelMap).length > maxGroups;
    const plural = {
        site: 'sites',
        agency: 'agencies',
        organization: 'organizations',
        collaborator: 'collaborators',
    };

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const doQuery = () => {
        return taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: evalCycleSelectorField || [],
                filter: getTeamMemberFilter(teamMemberSelectorField),
                siteIds: siteSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    const CACHE_DATA = false;
    // localStorage.setItem('sectionC', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('sectionC'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('sectionC', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    const parseChartDataFor = assignmentId => {
        const { siteList } = data.groups;
        const groups = groupLevelMap;
        const d = [];
        const key = assignmentId === '3' ? 'cg' : 'y';

        for (var g of Object.keys(groups)) {
            let barItem = { label: g };
            let totalMean = 0;
            let numSitesWithData = 0;
            let groupN = 0;
            for (var siteId of groups[g]) {
                const site = siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    const avg = site[key + 'Roles'][key + 'Summary'].mean;
                    if (avg) {
                        numSitesWithData += 1;
                        totalMean += avg;
                        groupN += site[key + 'Roles'][key + 'Summary'].N;
                    }
                }
            }
            barItem.mean = round(totalMean / numSitesWithData, 2) || 0;
            if (groupN > 0) {
                d.push(barItem);
            }
        }
        return d;
    };

    const parseTableDataFor = assignmentId => {
        const groups = groupLevelMap;
        let tableData = [];
        let groupsWithMean = 0;
        let totalGroupMean = 0;
        const key = assignmentId === '3' ? 'cg' : 'y';
        for (var g of Object.keys(groups)) {
            let groupTotalMean = 0;
            let sitesWithMean = 0;
            let groupN = 0;
            let groupRow = { label: g };
            for (var siteId of groups[g]) {
                const site = data.groups.siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    const siteMean = site[key + 'Roles'][key + 'Summary'].mean;
                    if (siteMean) {
                        groupTotalMean += siteMean;
                        sitesWithMean += 1;
                        groupN += site[key + 'Roles'][key + 'Summary'].N;
                    }
                }
            }
            const score = groupTotalMean / sitesWithMean || 0;
            if (score > 0) {
                groupsWithMean += 1;
                totalGroupMean += score;
            }
            groupRow.score = round(score, 2);
            if (groupN > 0) {
                tableData.push(groupRow);
            }
        }
        tableData.push({
            label: `${respondents[assignmentId].label} Mean:`,
            score: `${round(totalGroupMean / groupsWithMean, 2) || 0}%`,
        });
        return tableData;
    };

    const parseSummaryChartData = () => {
        const totalCg = [];
        const totalY = [];
        for (const g of Object.keys(groupLevelMap)) {
            let subtotalCg = [];
            let subtotalY = [];
            for (var site of data.groups.siteList) {
                if (groupLevelMap[g].includes(site.group.groupId)) {
                    if (site.yRoles.ySummary.mean) {
                        subtotalY.push(site.yRoles.ySummary.mean);
                    }
                    if (site.cgRoles.cgSummary.mean) {
                        subtotalCg.push(site.cgRoles.cgSummary.mean);
                    }
                }
            }
            if (subtotalCg.length > 0) {
                totalCg.push(round(mean(subtotalCg), 2));
            }
            if (subtotalY.length > 0) {
                totalY.push(round(mean(subtotalY), 2));
            }
        }
        let d = [];
        if (respondentSelectorField.includes('3')) {
            d.push({
                respondent: 'Caregiver',
                mean: round(mean(totalCg), 2) || 0,
            });
        }
        if (respondentSelectorField.includes('5')) {
            d.push({ respondent: 'Youth', mean: round(mean(totalY), 2) || 0 });
        }
        return d;
    };

    const getMarkersFor = assignmentId => {
        const benchmark = 'upper';
        const key = respondents[assignmentId].label.replace(/\s/g, '');
        let markers = [];
        for (var i of [
            // ['Inadequate', 'red'],  remove this benchmark per client and add text
            ['Adequate', 'blue'],
            ['Borderline', 'orange'],
            ['HighFidelity', 'green'],
        ]) {
            const value = window.cccisd.appDefs.wfiBenchmarks[`${key}_${i[0]}_${benchmark}`].satisfaction;

            const lower = window.cccisd.appDefs.wfiBenchmarks[`${key}_${i[0]}_lower`].satisfaction;

            const mark = {
                axis: 'y',
                value: lower, // per client, show line at bottom of range
                legend: `${i[0].replace(/([A-Z])/g, ' $1')} (${lower}-${value})`,
                lineStyle: {
                    stroke: i[1],
                },
                textStyle: {
                    fill: i[1],
                },
                legendOffsetY: 5,
                legendOffsetX: -150,
            };
            markers.push(mark);
        }
        return markers;
    };

    const itemTableDataFor = assignmentId => {
        const groups = groupLevelMap;
        let tableData = [];
        const key = assignmentId === '3' ? 'cg' : 'y';
        for (var group of Object.keys(groups)) {
            let groupItemTotal = { C1: 0, C2: 0, C3: 0, C4: 0 };
            let sitesWithYouth = { C1: 0, C2: 0, C3: 0, C4: 0 };
            let dataItem = { label: group };
            let groupN = 0;
            for (var siteId of groups[group]) {
                const site = data.groups.siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    for (var item of items) {
                        const count = site[key + 'Roles'][key + 'Summary'][item];
                        if (count) {
                            groupItemTotal[item] += count;
                            sitesWithYouth[item] += 1;
                        }
                    }
                    groupN += site[key + 'Roles'][key + 'Summary'].N;
                }
            }
            for (var totalItem of Object.keys(groupItemTotal)) {
                const score = round(groupItemTotal[totalItem] / sitesWithYouth[totalItem] || 0, 2);
                dataItem[totalItem] = `${score}, N=${groupN}`;
            }
            if (groupN > 0) {
                tableData.push(dataItem);
            }
        }

        return tableData;
    };

    const itemChartDataFor = item => {
        const { siteList } = data.groups;
        let barGroups = [];

        for (var g of Object.keys(groupLevelMap)) {
            let barGroup = { label: g };

            let caregiverGroupTotal = 0;
            let sitesWithCaregiver = 0;

            let youthGroupTotal = 0;
            let sitesWithYouth = 0;
            for (var siteId of groupLevelMap[g]) {
                const site = siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    const yScore = site.yRoles.ySummary[item];
                    if (yScore) {
                        youthGroupTotal += yScore;
                        sitesWithYouth += 1;
                    }
                    const cgScore = site.cgRoles.cgSummary[item];
                    if (cgScore) {
                        caregiverGroupTotal += cgScore;
                        sitesWithCaregiver += 1;
                    }
                }
            }
            barGroup.caregiver = round(caregiverGroupTotal / sitesWithCaregiver, 2) || 0;
            barGroup.youth = round(youthGroupTotal / sitesWithYouth, 2) || 0;
            barGroups.push(barGroup);
        }
        return barGroups;
    };

    const filteredRespondents = ['3', '5'].filter(id => respondentSelectorField.includes(id));

    const levels = [
        { name: 'highFidelity', label: 'High Fidelity' },
        { name: 'adequate', label: 'Adequate' },
        { name: 'borderline', label: 'Borderline' },
        { name: 'inadequate', label: 'Inadequate' },
    ];

    const scales = [
        {
            name: 'satisfaction',
            label: 'Satisfaction',
        },
    ];

    return (
        <>
            <div>
                {renderTitle('Total Satisfaction Score')}
                {renderDescription(`Any score below the Borderline range is considered in the Inadequate range.`)}
                {showChart(
                    <SummaryChart data={!loading && parseSummaryChartData()} markers={getMarkersFor('3')} />,
                    loading
                )}
            </div>

            {filteredRespondents.map(id => {
                const respondent = respondents[id];
                const type = respondent.label
                    .replace(/([A-Z])/g, '$1')
                    .replace(/^./, str => {
                        return str.toUpperCase();
                    })
                    .replace(' ', '');
                return (
                    <div key={id}>
                        <div>
                            {renderSubtitle(
                                `Section C. Total Satisfaction Score by ${startCase(groupLevel)} for WFI-EZ ${
                                    respondent.label
                                } Form`
                            )}
                            {hideChart
                                ? hiddenChart(
                                      `Chart hidden for reports with more than ${maxGroups} ${plural[groupLevel]}`
                                  )
                                : showChart(
                                      <RespondentTotalChart
                                          data={!loading && parseChartDataFor(id)}
                                          markers={getMarkersFor(id)}
                                      />,
                                      loading
                                  )}
                        </div>
                        {showTable(
                            <RespondentTotalTable data={!loading && parseTableDataFor(id)} groupLevel={groupLevel} />,
                            loading
                        )}
                        {showTable(<BenchmarkTable type={type} upper scales={scales} levels={levels} />, loading)}
                    </div>
                );
            })}
            {renderSubtitle('Item Responses')}
            {renderDescription(
                `Section C items are rated on a 5-point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). Users also have the option to respond "don't know" to any item, which renders the item "blank".`
            )}

            {items.map(item => {
                return (
                    <div key={item}>
                        {renderSubtitle(`Item ${item}`)}
                        {hideChart
                            ? hiddenChart(`Chart hidden for reports with more than ${maxGroups} ${plural[groupLevel]}`)
                            : showChart(
                                  <ItemRespondentChart
                                      data={!loading && itemChartDataFor(item)}
                                      keys={filteredRespondents.map(id => respondents[id].name)}
                                  />,
                                  loading
                              )}
                    </div>
                );
            })}

            {filteredRespondents.map(id => {
                const respondent = respondents[id];

                return (
                    <div key={id}>
                        <div>
                            {renderSubtitle(
                                `Section C Item Responses by ${startCase(groupLevel)} for WFI-EZ ${
                                    respondent.label
                                } Form`
                            )}
                            {showTable(
                                <ItemRespondentTable
                                    data={!loading && itemTableDataFor(id)}
                                    respondent={respondent}
                                    items={items}
                                    groupLevel={groupLevel}
                                />,
                                loading
                            )}
                        </div>
                        {showTable(
                            <ItemQuestionMeans items={items} level={respondent.label.replace(' ', '')} />,
                            loading
                        )}
                        {showTable(<QuestionTable items={items} type={respondent.label} />, loading)}
                    </div>
                );
            })}
        </>
    );
};

SatisfactionSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(SatisfactionSection);
