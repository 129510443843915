import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round, mean } from 'lodash';
import { hiddenChart } from 'js/vendor/reports/helpers.js';

const ElementItemChart = props => {
    const { groupLevelMap, data, subscale, respondent } = props;

    const getData = () => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            var row = { group };
            var itemTotals = {};
            var groupItemNs = {};
            for (var siteId of groupLevelMap[group]) {
                const site = data.groups.siteList.find(s => siteId === s.group.groupId);
                if (site) {
                    for (var item of subscale.items) {
                        const siteN = site.descendantRoles[respondent.name]['N' + item];
                        if (siteN) {
                            if (groupItemNs[item]) {
                                groupItemNs[item] += siteN;
                            } else {
                                groupItemNs[item] = siteN;
                            }
                        }
                        const siteItemMean = site.descendantRoles[respondent.name][item];
                        if (siteItemMean) {
                            if (itemTotals[item]) {
                                itemTotals[item] += siteItemMean;
                            } else {
                                itemTotals[item] = siteItemMean;
                            }
                        }
                    }
                }
            }
            let rowHasData = false;
            for (var i of subscale.items) {
                const countYes = itemTotals[i] || 0;
                const percentYes = groupItemNs[i] ? round(countYes / groupItemNs[i], 2) : 0;

                if (groupItemNs[i] > 0) {
                    rowHasData = true;
                }

                row[i] = `${percentYes}, N=${groupItemNs[i] || 0}`;
            }
            if (rowHasData) {
                rows.push(row);
            }
        }

        const tr = {};
        const chartData = [];
        for (const r of rows) {
            for (const q of Object.keys(r).filter(ro => ro !== 'group')) {
                if (tr[q]) {
                    tr[q].push(parseFloat(r[q].split(',')[0], 10));
                } else {
                    tr[q] = [parseFloat(r[q].split(',')[0], 10)];
                }
            }
        }
        for (const k of Object.keys(tr)) {
            chartData.push({
                label: k,
                mean: round(mean(tr[k]), 2) || 0,
            });
        }
        return chartData;
    };

    const chartData = getData();
    const isNoData = d => {
        let noData = true;
        for (var i of d) {
            if (noData && i.mean !== 0) {
                noData = false;
            }
        }
        return noData;
    };

    if (isNoData(chartData)) {
        return hiddenChart(`No data for ${props.subscale.label} items by ${props.respondent.label}.`);
    }

    return (
        <div style={{ width: '770px', height: '300px' }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                maxValue={2}
                minValue={-2}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                legends={[]}
                axisBottom={{ tickRotation: 45 }}
                keys={['mean']}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{ top: 15, right: 50, bottom: 50, left: 50 }}
            />
        </div>
    );
};

ElementItemChart.propTypes = {
    subscale: PropTypes.object,
    respondent: PropTypes.object,
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
};

export default ElementItemChart;
