import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { connect } from 'react-redux';
import { mean, round } from 'lodash';

const SummaryChart = props => {
    const { data, respondents, items, respondentSelectorField, groupLevelMap } = props;

    const getData = () => {
        const chartData = [];
        for (const item of items) {
            let barGroup = { label: item };
            for (const r of respondentSelectorField) {
                const respondent = respondents[r];
                const groupAvgs = [];
                for (var g of Object.keys(groupLevelMap)) {
                    let groupTotal = 0;
                    let groupN = 0;
                    for (const site of data.groups.siteList) {
                        if (groupLevelMap[g].includes(site.group.groupId)) {
                            if (site.descendantRoles[respondent.name].totalN) {
                                groupN += site.descendantRoles[respondent.name].totalN;
                            }
                            if (site.descendantRoles[respondent.name][item]) {
                                groupTotal += site.descendantRoles[respondent.name][item];
                            }
                        }
                    }
                    if (groupN > 0) {
                        groupAvgs.push(round((groupTotal / groupN) * 100));
                    }
                }
                if (groupAvgs.length > 0) {
                    barGroup[respondent.label] = round(mean(groupAvgs));
                }
            }
            chartData.push(barGroup);
        }
        return chartData;
    };

    const chartData = getData();

    return (
        <div style={{ width: '770px', height: '300px' }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value)}
                labelSkipWidth={0}
                labelSkipHeight={0}
                keys={respondentSelectorField.map(a => respondents[a].label)}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
            />
        </div>
    );
};

SummaryChart.propTypes = {
    data: PropTypes.array,
    respondents: PropTypes.object,
    items: PropTypes.array,
    respondentSelectorField: PropTypes.object,
    groupLevelMap: PropTypes.object,
    // redux
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, {})(SummaryChart);
