import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const ItemRespondentChart = props => {
    const { data, keys } = props;

    const longestLabel = Math.max(
        ...data.map(o => {
            return o.label.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                keys={keys}
                axisBottom={{
                    tickRotation: 45,
                }}
                minValue={-2}
                maxValue={2}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
                data={data}
            />
        </div>
    );
};

ItemRespondentChart.propTypes = {
    data: PropTypes.object,
    keys: PropTypes.array,
};

export default ItemRespondentChart;
