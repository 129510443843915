import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const DataCollectionInfoTable = props => {
    const getData = () => {
        let rows = [];
        const { deploymentList } = props.data.flows;
        for (var cycle of deploymentList) {
            const siteId = parseInt(cycle.siteId, 10);
            const siteName = props.groups.find(g => g.group.groupId === siteId).group.label;
            const responseRate = cycle.numCompleted - cycle.invalid;
            const responseRateCount = cycle.respondentCount - cycle.invalid;
            // responseRateCount: eval(
            //     subtract: { field: "respondentCount", takeAwayValue: { field: "invalid" } }
            // )
            rows.push({
                site: siteName,
                cycle: cycle.label,
                cycleKey: cycle.label + siteName,
                sampleSize: responseRateCount,
                numCompleted: responseRate,
                responseRate: `${round((responseRate / responseRateCount) * 100, 2) || 0}%`,
            });
        }
        return rows;
    };

    const cols = [
        { name: 'site', label: 'Site', sort: true, filter: true },
        { name: 'cycle', label: 'Evaluation Cycle', sort: true, filter: true },
        { name: 'sampleSize', label: 'Sample Size' },
        { name: 'numCompleted', label: 'Number of completed TOM 2.0 forms' },
        {
            name: 'responseRate',
            label: 'Response Rate',
            tooltip:
                'The response rate is calculated by dividing the number of completed TOM 2.0s by the number of forms that could have been administered (i.e. the youth was enrolled in Wraparound, was not institutionalized, and accurate contact information was provided).',
        },
    ];

    return <Table data={getData()} rowKey="cycleKey" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

DataCollectionInfoTable.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
};

export default DataCollectionInfoTable;
