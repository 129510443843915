import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const RespondentItemChart = props => {
    const {
        data,
        respondents,
        items,
        groupLevel,
        groupLevelMap,
        assignmentId,
        renderSubtitle,
        hiddenChart,
        hideChart,
    } = props;

    const getData = item => {
        const { siteList } = data.groups;
        const type = respondents[assignmentId];
        let barGroup = { item };
        for (var g of Object.keys(groupLevelMap)) {
            const sites = groupLevelMap[g];
            let groupTotal = 0;
            let groupN = 0;
            for (var siteId of sites) {
                const site = siteList.find(s => s.group.groupId === siteId);
                const siteN = site && site.descendantRoles[type.name].totalN;
                if (siteN) {
                    groupN += siteN;
                }
                groupTotal += (site && site.descendantRoles[type.name][item]) || 0;
            }
            barGroup[g] = round((groupTotal / groupN) * 100 || 0);
        }
        return [barGroup];
    };

    const siteNames = data.groups.siteList.map(site => {
        return site.group.label;
    });

    return (
        <>
            {items.map(item => {
                const chartData = getData(item);
                const longestLabel = Math.max(
                    ...Object.keys(chartData[0]).map(o => {
                        return o.length;
                    })
                );
                const right = 9 * longestLabel + 20;

                return (
                    <>
                        {renderSubtitle(`Item ${item} - ${respondents[assignmentId].label}`)}
                        <div
                            style={{
                                width: '770px',
                                height: `400px`,
                            }}
                        >
                            {hideChart ? (
                                hiddenChart()
                            ) : (
                                <ResponsiveBar
                                    indexBy="item"
                                    groupMode="grouped"
                                    maxValue={100}
                                    labelFormat={d => <tspan y={-8}>{d}</tspan>}
                                    label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                                    labelSkipWidth={0}
                                    labelSkipHeight={0}
                                    keys={groupLevel === 'site' ? siteNames : Object.keys(groupLevelMap || {})}
                                    theme={{ fontSize: 16 }}
                                    data={getData(item)}
                                    margin={{ top: 15, right, bottom: 50, left: 50 }}
                                />
                            )}
                        </div>
                    </>
                );
            })}
        </>
    );
};

RespondentItemChart.propTypes = {
    data: PropTypes.array,
    respondents: PropTypes.object,
    items: PropTypes.array,
    respondentSelectorField: PropTypes.object,
    assignmentId: PropTypes.number,
    groupLevelMap: PropTypes.object,
    renderSubtitle: PropTypes.func,
    hiddenChart: PropTypes.func,
    hideChart: PropTypes.bool,
};

export default RespondentItemChart;
