import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const SubscaleItemChart = props => {
    const { data, subscale } = props;

    const getData = d => {
        const rows = [];
        const ccmap = {};
        for (const item of subscale.items) {
            for (const cc of d.flows[item].frequency) {
                if (cc.value) {
                    if (ccmap[cc.value]) {
                        ccmap[cc.value][item] = { score: cc.avg, nVal: cc.count };
                    } else {
                        ccmap[cc.value] = { [item]: { score: cc.avg, nVal: cc.count } };
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = {
                cc,
                careCoord: cc.split(', ')[0],
            };
            for (const item of Object.keys(ccmap[cc])) {
                row[`${item}-score`] = ccmap[cc][item].score !== null ? ccmap[cc][item].score * 100 : null;
                row[`${item}-nVal`] = ccmap[cc][item].nVal;
            }
            rows.push(row);
        }
        let total = { careCoord: 'Total: ', cc: 'totalRow' };
        for (var key of Object.keys(rows[0])) {
            if (key.includes('score')) {
                let allNull = true;
                for (var r of rows) {
                    if (r[key] !== null) {
                        allNull = false;
                        break;
                    }
                }
                if (allNull) {
                    total[key] = '';
                } else {
                    let colTotal = 0;
                    let numNull = 0;
                    for (var ro of rows) {
                        if (ro[key] === null) {
                            numNull += 1;
                        } else {
                            colTotal += ro[key];
                        }
                    }
                    total[key] = `${round(colTotal / (rows.length - numNull), 2)}%`;
                }
            }
        }
        const bars = [];
        for (const k of Object.keys(total)) {
            if (k.includes('score')) {
                total[k] &&
                    bars.push({
                        subscale: k.split('-')[0].split('').reverse().join(''),
                        score: parseFloat(total[k].replace('%', ''), 10),
                    });
            }
        }
        return bars;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.subscale.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel + 10}px` }}>
            <ResponsiveBar
                indexBy="subscale"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                keys={['score']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 30, left: 50 }}
            />
        </div>
    );
};

SubscaleItemChart.propTypes = {
    data: PropTypes.object,
    subscale: PropTypes.object,
};

export default SubscaleItemChart;
