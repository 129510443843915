import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';

const items3pt = ['D5', 'D6', 'D7', 'D8', 'D9'];

const ElementItemChart = props => {
    const { data, respondents, subscale } = props;

    const getData = () => {
        const chartData = [];
        for (const item of subscale.items) {
            let barGroup = { label: item };
            for (const r of respondents) {
                const resItems = [];

                if (data.flows[r.label].frequency) {
                    for (const cc of data.flows[r.label].frequency) {
                        if (cc.value) {
                            const sum = cc[`${item}sum`];
                            const count = cc[`${item}count`];
                            const avg = sum / count;
                            if (avg || avg === 0) {
                                if (items3pt.includes(item)) {
                                    resItems.push(avg);
                                } else {
                                    resItems.push(avg * 100);
                                }
                            }
                        }
                    }
                }
                barGroup[r.label] = round(mean(resItems), 2);
            }

            chartData.push(barGroup);
        }
        return chartData;
    };

    const chartData = getData();
    const yAxisMax = subscale.items.some(i => items3pt.includes(i)) ? 3 : 100;

    return (
        <div style={{ width: '770px', height: '300px' }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                maxValue={yAxisMax}
                minValue={0}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                axisBottom={{ tickRotation: 45 }}
                keys={respondents.map(r => r.label)}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{
                    top: 15,
                    right: 175,
                    bottom: 50,
                    left: 50,
                }}
            />
        </div>
    );
};

ElementItemChart.propTypes = {
    subscale: PropTypes.object,
    respondents: PropTypes.array,
    filters: PropTypes.object,
    data: PropTypes.object,
};

export default ElementItemChart;
