import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';

const OverallFidelityChart = props => {
    const { groupLevelMap, data, subscales, respondents } = props;

    const chartify = d => {
        let chartData = [];
        for (const subscale of subscales) {
            const barGroup = { subscale: subscale.label };
            for (const respondent of Object.keys(data)) {
                const rows = [];
                for (const group of Object.keys(groupLevelMap)) {
                    let row = { group };
                    let totalScaleMeans = {};
                    let sitesWithScale = {};
                    if (data[respondent].groups) {
                        for (const site of data[respondent].groups.siteList) {
                            if (groupLevelMap[group].includes(site.group.groupId)) {
                                const key =
                                    respondents[Object.keys(respondents).find(i => respondents[i].name === respondent)]
                                        .short + subscale.short;
                                const avg = site.descendantRoles[respondent][key];
                                if (avg) {
                                    if (totalScaleMeans[subscale.name]) {
                                        totalScaleMeans[subscale.name] += avg;
                                    } else {
                                        totalScaleMeans[subscale.name] = avg;
                                    }
                                    if (sitesWithScale[subscale.name]) {
                                        sitesWithScale[subscale.name] += 1;
                                    } else {
                                        sitesWithScale[subscale.name] = 1;
                                    }
                                }
                            }
                        }
                    }
                    for (let scalename of Object.keys(totalScaleMeans)) {
                        const groupScaleMean = round(totalScaleMeans[scalename] / sitesWithScale[scalename], 2);
                        row[scalename] = groupScaleMean;
                    }
                    const rowHasData = Object.keys(row).some(sc => row[sc] > 0);
                    if (rowHasData) {
                        rows.push(row);
                    }
                }
                barGroup[respondents[Object.keys(respondents).find(i => respondents[i].name === respondent)].label] =
                    round(mean(rows.map(r => r[subscale.name])));
            }
            chartData.push(barGroup);
        }
        return chartData;
    };

    return (
        <div style={{ width: '770px', height: '450px' }}>
            <ResponsiveBar
                indexBy="subscale"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                axisBottom={{ tickRotation: 45 }}
                keys={props.filters.respondentSelectorField.map(id => props.respondents[id].label)}
                theme={{ fontSize: 16 }}
                data={chartify(props.data)}
                margin={{ top: 70, right: 50, bottom: 155, left: 50 }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 15,
                        translateY: -40,
                        itemsSpacing: 20,
                        itemWidth: 150,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

OverallFidelityChart.propTypes = {
    data: PropTypes.object,
    groups: PropTypes.array,
    filters: PropTypes.object,
    respondents: PropTypes.object,
    groupLevelMap: PropTypes.object,
    subscales: PropTypes.array,
};

export default OverallFidelityChart;
