import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const RespondentTotalChart = props => {
    const { data, respondent, careCoordSelectorField, markers } = props;

    const getData = () => {
        const ccList = careCoordSelectorField.map(cc => {
            const pawnDashIndex = cc.lastIndexOf('-');
            return cc.substring(0, pawnDashIndex);
        });
        const bars = [];

        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                if (ccList.includes(cc.value)) {
                    bars.push({ label: cc.value, mean: round(cc.mean, 2) });
                }
            }
        }
        return bars;
    };

    const chartData = getData();

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.label.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${600 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                label={d => (d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value || ' ')}
                labelSkipWidth={0}
                labelSkipHeight={0}
                keys={['mean']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
                markers={markers}
            />
        </div>
    );
};

RespondentTotalChart.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    careCoordSelectorField: PropTypes.object,
    markers: PropTypes.object,
};

export default RespondentTotalChart;
